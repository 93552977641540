const listingUiState = reactive({
  columns: 0,
})

export default () => {
  if (listingUiState.columns === 0) {
    listingUiState.columns = useViewport().isLessThan('md') ? 2 : 4
  }
  const listingColumns = computed(() => toRaw(listingUiState).columns)

  const setColumns = (columns: number) => {
    listingUiState.columns = columns
  }

  const isColumn = (number: number): boolean => {
    return number === listingColumns.value
  }

  return {
    listingColumns,
    setColumns,
    isColumn,
  }
}
